.pino-attendee-form{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo{
        width: 180px;
        object-fit: cover;
    }
    form{
        height: 650px;
        padding: 40px 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(250, 232, 216, 0.75);
        border-radius: 10px;
        .inputGroup{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
            label{
                margin-bottom: 10px;
            }
            .input{
                height: 50px;
                outline: none;
                border: none;
                background-color: rgb(229, 211, 195);     
                padding: 15px;           
            }
        }
    }

    .waiting-screen{
        min-height: 200px;
        img{
            width: 180px;
            margin-bottom: 20px;
        }
        .time-elapsed, .people{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .social-icons{
            display: flex;
            align-items: center;
            gap: 10px;
            a{
                font-size: 18px;
                color: #A04422;
            }
        }

        .welcomeMessage{
            text-align: center;
            color: #A04422;
            font-weight: 600;
        }
    }
}