.pino-client-choose-branch{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .logo{
        width: 200px;
        object-fit: cover;
    }
    h3{
        font-size: 45px;
        font-weight: 600;
        margin-top: 20px;
    }
    .branches{
        display: flex;
        gap: 20px;
        margin-top: 30px;
        a{
            padding: 20px 40px;
            background-color: rgba(250, 232, 216, 0.75);
            border-radius: 10px;
            color: #A04422;
            text-decoration: none;
            font-size: 24px;
            font-weight: 600;
            transition: all ease 0.3s;
            &:hover{
                box-shadow: 7px 10px 15px #0004;

            }
        }
    }
}