#phone_number{
    width: 100%;
    height: 100%;
    background-color: unset;
    border: unset;
  }
  input[type="tel"] , input[type="tel"]:focus {
    width: 100% !important;
    height: 100% !important;
    background-color: unset !important;
    border: unset !important;
    box-shadow:none !important;
  }
  .flag-dropdown{
    background-color: unset !important;
    border: unset !important;
    margin-right: 24px;
  }
  .flag-dropdown:hover ,  .flag-dropdown:focus , .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: unset !important;
    border: unset !important;
    /* margin-right: 24px; */
  }