
.pino-modal-container{
    background: rgba(0, 0, 0, 0.50);
    height: 100vh;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
    z-index: 99;
    .modal-content-large{
        width: 55%;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 7px 10px 15px #0004;
        position: relative;
    }
}